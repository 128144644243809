.form-group-contact {
  position: relative;
}
.label-error {
  color: #da7777;
  position: absolute;
  top: 52px;
  font-size: 12px;
  left: 10px;
  margin-left: 23px;
  padding: 2px;
  background: white;
  border-radius: 10px;
}